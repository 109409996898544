@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap");

@mixin open-sans-light() {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

@mixin open-sans-regular() {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

@mixin open-sans-semi-bold() {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

@mixin open-sans-bold() {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

