@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../styles/utils";

.fillers-wrapper {
	@include padding(50,40,30);

	@include mobile {
		@include padding(20);
	}

	.side-bar {
		@include mobile {
			display: none;
		}
	}

	.menu-item-mimic {
		margin-bottom: toRem(20);
		.circle {
			margin: 0 auto;
			margin-bottom: toRem(10);
		}

		.small-text {
			margin: 0 auto;
		}
	}

	.text {
		@include padding(20);
	}
}