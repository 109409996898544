@import "./styles/fonts";
@import "./styles/colors";
@import "./styles/utils";

.App {
  text-align: center;

  .app-container {
    padding-top: toRem(56);
  }

  .move-right {
    @include hd {
      margin-left: 100px; //drawerWidth
    }

    @include mobile {
      margin-left: 0;
    }
  }

  .small-top {
    padding-top: toRem(50);
  }
}

.walkthrough-wrapper {

  .gDvSuy {
    justify-content: center;
  }
  
  .walk-nav-button {
    background-color: #c9db45;

    &:disabled {
      background-color: white;
      border: 1px solid #968c8c;
      color: #968c8c;
    }
  }
}