@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../styles/utils";

.header-wrapper {
  .header-list {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .app-toolbar {
    @include height(56);
    min-height: auto !important;
    background-color: black;

    .MuiToolbar-root {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      min-height: auto !important;

      h6 {
        color: white;
      }

      @include mobile {
        justify-content: space-between;
      }
    }

    .header-items-container {
      display: flex;
      width: 14rem;
      justify-content: flex-end;
      align-items: center;

      @include mobile {
        width: 10rem;
      }

      .ml-1 {
        margin-left: 1rem;
      }
  
      .MuiAvatar-root {
        width: 30px;
        height: 30px;
      }

      .search-btn {
        cursor: pointer;
      }

      .more-icon {
        cursor: pointer;
        @include mobile {
          display: none;
        }
      }
    }

    .name-div {
      display: flex;
      align-items: center;
    }

    .user-name-display {
      color: white;
      @include fontSize(20);
      @include open-sans-semi-bold;

      @include mobile {
        display: none;
      }
    }
  }

  .walkthrough-bar {
    background-color: transparent;
  }

  .no-user-app-toolbar {
    background-color: #1C5BFE;

    .MuiToolbar-root {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .drawer-wrapper {
    @include width(100);

    @include mobile {
      @include width(300)
    }
  }

  .desktop-drawer {
    .MuiDrawer-paper {
      z-index: 0;
    }
  }

  .list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #bcbbbe;
    margin: 0.5rem 0;

    svg {
      @include fontSize(24);
    }

    &:hover {
      cursor: pointer;
      color: black;
    }

    span {
      margin-top: 0.2rem;
      text-align: center;
      text-transform: uppercase;
      @include fontSize(10);
      opacity: 0;
      @include open-sans-semi-bold;
    }

    .add-icon-box {
      height: 56px;
      width: 56px;
      filter: drop-shadow(0 4px 5px rgba(0,0,0,.2));
      border-radius: 50%;
      background-color:   #2385f6;
      display: flex;
      justify-content: center;
      align-items: center;
  
      &:hover {
        color: white;
      }

      svg {
        color: white;
      }
    }

    &:hover {
      color: black !important;
      transition: all 0.3s ease-in-out;

      span {
        opacity: 1;
        transition: all 0.5s ease-in-out;
      }
    }
  
  }

  .selected-list-item {
    color: black !important;
    span {
      opacity: 1;
    }

  }

  .dummy-toolbar {
    @include height(56);
    min-height: auto !important;
    background-color:   #2385f6;
  }

  .user-avatar {
    cursor: pointer;
  }
}

.add-person-box {
  top: 15px;
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 40px;
  align-items: center;
  justify-content: center;
  height: 40px;
  display: flex;
  border-radius: 50%;
  color: #2385f6;
  background-color: white;
  box-shadow: 0 4px 5px rgba(0,0,0,.2)!important;
  display: none;
  @include mobile {
    display: flex;
  }
}

.mobile-drawer-wrapper {
  .mobile-dummy-toolbar {
    display: flex;
    align-items: center;
    @include padding(20,15);

    span {
      @include fontSize(16);
      color: #6f6f6f;
      margin-left: toRem(20);
    }
  }

  .mobile-list-item {
    .MuiListItemIcon-root {
      min-width: auto;
      svg {
        @include fontSize(24);
        margin-right: toRem(25);
      }
    }

    .MuiListItemText-root {
      span {
        @include fontSize(14);
        text-transform: capitalize;
      }
    }
  }
}

.user-dropdown {
  .MuiMenuItem-root {
    &:focus {
      background-color: rgba(0, 0, 0, 0.04);
      color: inherit;

      .MuiListItemText-primary {
        color: inherit;
      }
    }
  }

  .user-buttons {
    @include padding(10,20);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      &:first-child {
        margin-right: toRem(20);
      }
    }
  }
}

// profile dialog styles

.profle-modal {
  .MuiDialog-paperWidthSm {
    @include width(450);
  }

  .MuiDialogContent-root {
    padding: 0 !important;
  }

  .upload-image-wrapper {
    @include height(220);
    width: 100%;
    background-color: #f2f2f5;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .no-img-dummy {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      @include fontSize(110);
    }

    .upload-img {
      position: absolute;
      right: toRem(10);
      bottom: toRem(-10);
      background-color: #3085f6;

      svg {
        color: white;
        @include fontSize(32);
      }
    }
  }

  .profile-inputs-wrapper {
    @include padding(15, 20, 5);

    .profile-input {
      width: 100%;
      @include margin(15,0);
    }
  }
}