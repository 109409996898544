@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../styles/utils";

.tabs-wrapper {
  .MuiTabs-indicator {
    background-color: #4191ff;
  }

  .MuiTabs-root {
    border-bottom: 1px solid #f7ebeb;
  }

  .MuiTab-root {
    min-width: unset;
  }

  .tab-item {
    span {
      align-items: flex-start;
      @include fontSize(14);
    }
  }
}