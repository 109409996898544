@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../styles/utils";

.generic-modal {
  .MuiDialog-paperWidthSm {
    min-width: toRem(300);
    // max-width: toRem(400);
  }
}

.change-background-modal {
    .title {
      @include padding(16);
    }
  
    .actions {
      @include padding(16);
    }
  
    .MuiDialog-paperWidthSm {
      width: auto;
    }
  
    .background-content {
      border: none;
      @include padding(0,16);
  
      .grid-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        @include padding(20, -20);
        grid-gap: toRem(20);
  
        @include mobile {
          grid-template-columns: repeat(3, 1fr);
        }
      }
  
      .grid-container > div {
        background-color: rgba(255, 255, 255, 0.8);
        text-align: center;
        @include borderRadius(6);
        position: relative;
        border: 1px solid #bcbbbe;
        @include width(80);
  
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
  
        .content {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 0;
          bottom: 0;
          @include borderRadius(6);
          background-size: cover;
          cursor: pointer;
        }
  
        svg {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 0;
          bottom: 0;
          @include fontSize(30);
          color: #676767;
          cursor: pointer;
        }
      }

      .selected {
        border: 2px solid #0098f7 !important;
      }
    }
  }

// choose template modal styles

.choose-template-modal {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: toRem(15);

    svg {
      cursor: pointer;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @include padding(20, -20);
    grid-gap: toRem(20);

    @include mobile {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .selected {
    border: 2px solid #0098f7 !important;
  }

  .template-box {
    @include width(100);
    margin-bottom: toRem(10);

    @include mobile {
      width: auto;
      @include margin(10);
    }

    span {
      color: #595959;
      @include fontSize(12);
      @include open-sans-semi-bold;
    }

    .template {
      width: 100%;
      border: 1px solid #bcbbbe;
      @include borderRadius(4);
      position: relative;
      cursor: pointer;
      margin-bottom: toRem(5);
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border: none;
      }

      &:after {
        content: "";
          display: block;
          padding-bottom: 100%;
      }

      .dummy {
        width: 75%;
        height: 75%;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        position: absolute;
      }
    }
    
  }
}

// share document modal 

.share-document-modal {
  .MuiDialogContent-root {
    flex: none;
    @include padding(8,24);
  }

  .MuiDialogActions-root {
    @include padding(8,24,10);
  }

  .MuiDialog-paperWidthSm {
    @include width(600);
    max-width: auto;

    @include mobile {
      width: 100%;
      max-width: unset;
      max-height: unset;
      margin: 0;
      height: 100%;
      $height: toRem(64);
      height: calc(100% - #{$height});
      position: fixed;
      bottom: 0;
    }
  }

  .add-message-textarea {
    width: 100%;
  }

  .assignee-autocomplete-wrap {
    width: 100%;
    @include margin(20,0);
  }

  .copy-link-box {
    margin-top: toRem(10);
    @include open-sans-semi-bold;
    display: flex;
    align-items: center;
    color: #3085f6;
    text-transform: uppercase;
    @include fontSize(12);
    cursor: pointer;

    svg {

    }

    span {
      margin-left: toRem(10);
    }
  }
}

// add new person dialog styles

.add-new-user-dialog {
  .MuiFormControl-root {
    width: 100%;
  }

  .MuiDialog-paperWidthSm {
    max-width: toRem(624);
    @include mobile {
      width: 100%;
      max-width: unset;
      max-height: unset;
      margin: 0;
      $height: toRem(64);
      height: calc(100% - #{$height});
      position: fixed;
      bottom: 0;
    }
  }

  .api-error {
    @include fontSize(12);
    margin-bottom: toRem(10);
    color: #f44336;
  }

  h2 {
    @include fontSize(20);
    @include open-sans-semi-bold;
    margin: 0;
    color: black;
  }

  .title {
    @include padding(16,24,0);
  }

  .content {
    @include padding(0,24,8);
    flex: none;
  }

  .MuiDialogActions-root {
    @include padding(8,24,10);
  }

  .checkboxes-wrapper {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }

  .checkbox-container {
    padding: 0 !important;
  }
}

//save as copy modal styles

.save-as-copy-modal {
  .selected-node {
    background-color: rgba(48, 133, 246, 0.1);
  }

  .MuiDialog-paperWidthSm {
    @include width(400);

    @include mobile {
      width: 100%;
      max-width: unset;
      max-height: unset;
      margin: 0;
      height: 100%;
      $height: toRem(64);
      height: calc(100% - #{$height});
      position: fixed;
      bottom: 0;
    }
  }

  .MuiDialogContent-root {
    @include padding(20);
    flex: none;
  }

  .MuiDialogActions-root {
    @include padding(20);
  }

  h6 {
    margin-bottom: toRem(20);
  }

  .tree-label {
    display: flex;
    align-items: center;
    @include margin(5,0);
  
    svg {
      margin-right: toRem(10);
    }
  }
}