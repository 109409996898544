@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../styles/utils";

.document-drawer-wrapper {
  .drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include padding(10,10,10,20);
    border-bottom: 1px solid #f7ebeb;
  }
}

.document-settings-wrapper {
  @include width(400);
  @include open-sans-regular;

  @include mobile {
    width: 100%;
  }

  section {
    @include padding(10,20);
    border-bottom: 1px solid #f7ebeb;
  }

  .text-field {
    width: 100%;
    @include margin(15,0);
  }

  .flip-switch {
    justify-content: space-between;
    width: 100%;
    margin-left: 0;
  }

  .misc-detail {
    width: 100%;
    text-align: left;
    color: #595959;
    @include fontSize(14);
    @include open-sans-regular;
    @include margin(10,0);

    .MuiGrid-item {
      padding: 0.4rem 0
    }
  }
}

.permissions-content-wrapper {
  @include width(400);
  @include open-sans-regular;

  @include mobile {
    width: 100%;
  }

  .radios-wrapper {
    @include padding(20);
    border-bottom: 1px solid #f7ebeb;
  }

  .publish-type-box {
    border: 1px solid #eff2f3;
    display: flex;
    align-items: center;
    // @include width(250);
    @include borderRadius(6);
    cursor: pointer;
    @include padding(10);
    @include margin(15,0);

    .caption {
      text-transform: capitalize;
    }

    p {
      @include margin(10,0);
    }

    &:hover {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

  .assignee-autocomplete-wrap {
    @include padding(20);
    margin: 0 auto;
  }
}
