@import "./fonts";
@import "./colors";
@import "./utils";

html {
  height: 100%;
  scroll-behavior: smooth;

  @include mobile {
    font-size: 14px;
  }
  @include tablet {
    font-size: 11px;
  }
  @include desktop {
    font-size: 13px;
  }
  @include hd {
    font-size: 14px;
  }
  @include hdPlus {
    font-size: 15px;
  }
  @include fullHd {
    font-size: 18px;
  }
  @include quadHd {
    font-size: 20px;
  }
  @include uHd {
    font-size: 22px;
  }
}

body {
  @include open-sans-regular;
  font-size: 16px;
  color: #262626;
  padding: 0;
  margin: 0;
}

#root {
  min-height: 100%;
  position: relative;
}

h1 {
  @include fontSize(40);
  @include open-sans-semi-bold;
}

h1, h2 {
  @include fontSize(35);
  @include open-sans-semi-bold;
}

h3 {
  @include fontSize(30);
  @include open-sans-regular;
}

h4 {
  @include fontSize(28);
  @include open-sans-regular;
}

h5 {
  @include fontSize(24);
  @include open-sans-regular;
}

h6 {
  @include fontSize(20);
  @include open-sans-semi-bold;
  margin: 0;
  color: #262626;
}

.subtitle-big {
  @include fontSize(16);
  @include open-sans-regular;
}

.subtitle-small {
  @include fontSize(14);
  @include open-sans-regular;
}

button {
  span {
    @include fontSize(12);
    @include open-sans-semi-bold;
  }
}

.caption {
  @include fontSize(14);
  @include open-sans-semi-bold;
  color: #262626;
}

// common

.sign-link {
  color: #3085f6;
  text-decoration: none;

  &:hover, &:active {
    text-decoration: underline;
  }
}

.auth-button {
  text-transform: none !important;
  @include padding(10,0,10,0, !important);
  @include margin(20,0,20,0,  !important);

  span {
    @include fontSize(15, !important);
    @include open-sans-semi-bold;
  }
}

// password strength styles

// password strngth

.password-strength-meter {
  text-align: left;
  width: 100%;
  margin-top: toRem(10);
}

.password-strength-meter-progress {
-webkit-appearance: none;
appearance: none;
width: 100%;
height: 8px;
border-radius: 3px;
}

.password-strength-meter-progress0::-webkit-progress-value {
background-color: #eee;
} 

.password-strength-meter-progress1::-webkit-progress-value {
background-color: #F25F5C;
}

.password-strength-meter-progress2::-webkit-progress-value {
background-color: #FFE066;
}

.password-strength-meter-progress3::-webkit-progress-value {
background-color: #247BA0;
}

.password-strength-meter-progress4::-webkit-progress-value {
background-color: #35B34E;
}

.password-strength-meter-progress::-webkit-progress-bar {
background-color: #eee;
border-radius: 3px;
}

.password-strength-meter-label {
  @include fontSize(14);
  color: #364252;
}

.password-strength-meter-progress::-webkit-progress-value {
border-radius: 2px;
background-size: 35px 20px, 100% 100%, 100% 100%;
}


// material overrides

.MuiAutocomplete-popper {
  .MuiListItem-container {
    width: 100% !important;
  }
}