@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../styles/utils";

.notification-trigger {
  cursor: pointer;
}

.right-drawer-header {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h6 {
    @include margin(20,0);
  }

  .close-icon {
    cursor: pointer;
  }
}

.right-drawer-tabs {
  .MuiTabs-indicator {
    background-color: #4191ff;
  }

  .MuiTabs-flexContainer {

    @include mobile {
      display: block;
      overflow: scroll;
    }
    
    button {
      span {
        @include fontSize(11);
        @include open-sans-semi-bold;
        color: #BCBBBE;
      }

      @include mobile {
        min-width: 100px;
      }
    }
  }

  @include mobile {
    .MuiTabs-flexContainer {
      display: block;
      overflow: scroll;

      button {
        min-width: 100px;
      }
    }
  }

  .MuiTab-root {
    font-size: 1.7rem;
    min-width: auto;
  }

  .Mui-selected {
    .MuiTab-wrapper {
      color: #4191ff !important;

      svg {
        color: #4191ff !important;
      }
    }
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    top: 5px;
    right: 5px;
    background-color: #f83245;
  }
}

// drawer card styles

.drawer-card-wrapper {
  margin-bottom: toRem(20);

  .card-header-wrap {
    @include padding(16, 16, 0);
    .MuiCardHeader-content {
      text-align: left;
    }
    .MuiCardHeader-title {
      @include fontSize(14);
      @include open-sans-semi-bold;
      color: #262626;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  
    .MuiCardHeader-subheader {
      @include open-sans-regular;
      @include fontSize(12);
      color: #595959;
      white-space: normal;
    }  

    // .flag-button {
    //   svg {
    //     color: #E53935;
    //   }
    // }
  }

  .card-media-wrap {
    margin: 0 auto;
    margin-bottom: toRem(16);
    margin-top: toRem(16);
    @include height(200);
    @include width(200);

  }

  .card-video {
    margin: 0 auto;
    margin-bottom: toRem(16);
    margin-top: toRem(16);
  }

  .card-content-wrap {
    @include padding(10, 16, 0);

    .text-suggestion {
      border: 1px solid black;
      @include fontSize(13);
      @include open-sans-regular;
      color: rgba(0,0,0,0.6);
      @include padding(10);
      border: 1px solid #EDF0F2;
    }

    .comment-input {
      width: 100%;
      margin-top: toRem(10);
    }
  }

  .doc-details {
    @include fontSize(14);
    @include open-sans-semi-bold;
    color: #262626;
    margin: 0;
  }

  .desc {
    white-space: normal;
    text-align: left;
    @include fontSize(14);
    @include open-sans-regular;
    margin-bottom: 0;
  }

  .MuiCardActions-root {
    justify-content: flex-end;
  }

  .action-button {
    text-transform: uppercase;
    color: #676767;
    @include open-sans-semi-bold;

    span {
      @include fontSize(11);
    }

    .button-icon {
      margin-right: toRem(5);
      @include fontSize(15);
    }
  }
}

.no-notifications-container {
  text-align: center;
  margin-top: toRem(20);

  .main {
    @include open-sans-semi-bold;
    @include fontSize(20);
    color: #262626;
  }

  .desc {
    @include open-sans-regular;
    @include fontSize(16);
    color: #595959;
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  92% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

}

.animate-bell {
  animation: heartBeat 2s ease-in-out;
  animation-iteration-count: 5;
}