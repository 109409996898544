@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../styles/utils";

.dropdown-wrapper {
    .MuiListItem-button {
        .MuiTypography-body1 {
            @include fontSize(14);
        }
        &:hover {
            color: #3085f6 !important;

            svg {
                color: #3085f6 !important;
            }
        }
    }

    .add-border {
        border-bottom: 1px solid #f7ebeb;
    }
}
